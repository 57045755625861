// eslint-disable-next-line no-unused-vars
( ( {hooks: {addFilter, addAction}} ) => {
  const sliderExcludes = ['e-tbs--tab-slider-two'];
  addFilter( 'b3rg_tab_slider_exclude', 'b3rg/tab-slider', () => sliderExcludes );

  const breakpoints = {
    mobile: 575,
    'tablet-portrait': 991,
    'tablet-landscape': 1319,
    desktop: 9999,
  };

  addFilter( 'b3rg_slider_breakpoints', 'b3rg/tab-slider', () => breakpoints );
  
  // eslint-disable-next-line no-undef
} )( feWp );
